import request from '@/libs/request'

/**
 * 已购简历学员详情页
 */
export function getResumeDetailInfoApi(user_id) {
    return request({
        url: `/company/userBuyInfo?id=${user_id}`,
        method: 'get',
    })
}
/**
 * 收藏简历列表页
 */
export function getCollectionListApi(page, limit) {
    return request({
        url: `/company/collList?page=${page}&limit=${limit}`,
        method: 'post',
    })
}
/**
 * 移除收藏简历
 */
export function deleteCollectionListApi(user_id) {
    return request({
        url: `/company/delColl/${user_id}`,
        method: 'get',
    })
}