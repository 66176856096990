<template>
  <div class="resume-collection">
    <div class="c-title">简历收藏列表</div>
    <el-card>
      <!-- show-header 是否显示表头 -->
      <el-table
        ref="collectList"
        :data="collectList"
        :header-cell-style="{
          'text-align': 'center',
          background: '#f2f2f2',
          color: '#838a96',
          border: 'none',
        }"
        :cell-style="{ 'text-align': 'center', color: '#000' }"
        style="width: 100%"
        @row-click="goStdDetails"
        v-loading="loading"
        border
        :row-key="
          (row) => {
            return row.user_id;
          }
        "
        @selection-change="handleSelectionChange"
        @select="select"
      >
        <!-- <el-table-column type="selection" width="55" :reserve-selection="true">
        </el-table-column> -->
        <el-table-column min-width="80%" label="基本信息">
          <template slot-scope="scope">
            <el-row class="i-list">
              <el-col :span="4" class="coll">
                <el-image :src="imageSrc(scope.row)"></el-image>
                <div class="Real" v-if="scope.row.user_auth_state == 3">
                  <span>已实名</span>
                </div>
              </el-col>
              <el-col :span="20">
                <div class="i-info">
                  <div class="i-name">{{ scope.row.real_name | nameFormat}}</div>
                  <el-row class="i-content">
                    <el-col :span="24">
                      <ul class="c-info">
                        <!-- 性别 -->
                        <li class="i-item">
                          {{ scope.row.gender | genderFormat }}
                        </li>
                        <li class="i-item" v-if="scope.row.id_cart_num">{{ scope.row.id_cart_num }}岁</li>
                        <li class="i-item" v-if="scope.row.height">{{ scope.row.height }}cm</li>
                        <li class="i-item" v-if="scope.row.min_salary_range == 0 || scope.row.min_salary_range == null ">面议</li>
                        <li class="i-item" v-else>{{scope.row.min_salary_range}}-{{scope.row.max_salary_range}}k</li>
                        <li class="i-item" v-if=" scope.row.education_level">{{ scope.row.education_level }}</li>
                        <li class="i-item" v-if="scope.row.job_wanted == 1">正在找工作</li>
                        <li class="i-item" v-if="scope.row.job_wanted == 0">暂不找工作</li>
                        <!-- 身高 -->
                        <!-- <li class="i-item">{{ scope.row.height }}</li> -->
                        <!-- 学历 -->
                        <!-- <li class="i-item">{{ scope.row.education_level }}</li> -->
                      </ul>
                    </el-col>
                  </el-row>                  
                </div>                              
              </el-col>              
            </el-row>
            <div class="certName"><span v-for="(item,index) in scope.row.certName" :key="index" class="cert">{{item}}</span></div>
          </template>
        </el-table-column>
        <el-table-column min-width="20%" label="操作">
          <template slot-scope="scope">
            <div style="text-align: center;">
              <el-button
                size="small"
                class="el-btn"
                @click.stop="deleteCollect(scope.row.user_id)"
                >移除收藏</el-button
              >
            </div>
            <div v-if="scope.row.user_phone" class="userPhone">{{scope.row.user_phone}}</div>
            <div v-else>
              <look-phone
              :userId="scope.row.user_id"
              style="margin-top: 10px"
              ></look-phone>
            </div>           
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-row class="m-all">
        <el-col :span="13">
          <el-checkbox
            v-model="allCheck"
            @change="allCheckEvent"
            style="padding: 7px 0px; padding-left: 8px"
            >全选
          </el-checkbox>
        </el-col>
        <el-col :span="7" style="line-height: 35px; font-size: 14px">
          <span>
            已选择
            <strong>{{ selectedNum }}</strong>
            份简历
          </span>

          <span style="margin-left: 10px">
            总价:
            <strong>{{ totalPrice }}.00</strong>
            积分</span
          >
        </el-col>
        <el-col :span="4">
          <look-phone :userId="userIds" @refreshCollectionList="getCollectionList" :totalPrice="totalPrice"></look-phone>
        </el-col>
      </el-row> -->
      <el-row class="l-pagination">
        <Pagination
          :page.sync="page"
          :limit.sync="limit"
          :total="count"
          @getList="getCollectionList"
        />
      </el-row>
    </el-card>
  </div>
</template>

<script>
  import {
    getCollectionListApi,
    deleteCollectionListApi
  } from '@/api/resume'
  import lookPhone from '@/components/lookPhone/index.vue'
  import Pagination from '@/components/Pagination/index.vue'
  export default {
    name: 'resumeCollection',
    components: {
      lookPhone,
      Pagination
    },
    data() {
      return {
        collectList: [],
        loading: false,
        page: 1,
        limit: 5,
        count: 0,
        userIds: [],
        allCheck: false, //全部选择框的选择与否
        checkList: [], //被选择的,
        selectOff: [] //不被选择的
      }
    },
    filters: {
      genderFormat(val) {
        if (val == 1) {
          return val = '男'
        } else if (val == 2) {
          return val = '未知'
        } else {
          return val = '女'
        }
      }
    },
    computed: {
      selectedNum() {
        let result = 0
        result = this.checkList.length
        return result
      },
      totalPrice() {
        let result = 0
        result = this.checkList.length * 2
        return result
      }
    },
    watch: {
      //监听渲染列表数组的变化，一旦变化 执行相关函数，使全选状态下，每一页按照全选显示
      collectList: {
        handler(value) {
          if (this.allCheck) {
            let that = this;
            let len = that.checkList.length;
            let lenOff = that.selectOff.length;
            value.forEach(row => {
              // 遍历新请求回来的当页数据，和已经选择的数组中所有对象对比，如果有相同的，说明已经选择了，不再选择，其他没有选择的设置为选中状态
              for (let i = 0; i < len; i++) {
                if (row.user_id === that.checkList[i].user_id) {
                  that.$refs.collectList.toggleRowSelection(row, false);
                  break;
                } else {
                  that.$refs.collectList.toggleRowSelection(row, true);
                }
              }
              // 循环遍历新的请求回来的当页数据，和手动未选择的数组对比，如果有相同的，将其样式显示为未选择状态
              for (let i = 0; i < lenOff; i++) {
                if (row.user_id === that.selectOff[i].user_id) {
                  that.$refs.collectList.toggleRowSelection(row, false);
                }
              }
            });
          }
        },
        deep: true
      },
      checkList: {
        handler(val) {
          this.userIds = val.map((item) => {
            return item.user_id
          })
        },
        deep: true
      }
    },
    created() {
      this.getCollectionList()
    },

    methods: {
      //1.选择所有的函数
      allCheckEvent() {
        //1.1当选择所有 选择框状态为勾时
        if (this.allCheck) {
          //1.1.1遍历当时展示的数组，使所有的多选框为勾选择状态
          this.collectList.forEach(row => {
            this.$refs.collectList.toggleRowSelection(row, true)
          })
        } else {
          //1.2当选择所有 选择框状态为 空时
          //1.2.1 遍历当时展示的数组，使所有的多选框为 未 选择状态
          this.$refs.collectList.clearSelection()
        }
      },
      //2.当选择项发生变化时会触发该事件
      handleSelectionChange(val) {
        this.checkList = val
      },
      //3.当用户手动勾选数据行的 Checkbox 时触发的事件
      select(selection, row) {
        if (this.allCheck) {
          let selected = selection.length && selection.indexOf(row) !== -1; //为true时选中，为 0 时（false）未选中
          //当手动操作为未选中状态时
          if (!selected) {
            //将未选中的数据放在一个变量里
            this.selectOff.push(row)
          } else {
            // 为了使再次选中后，将未选择数组中的该数据删除掉，遍历未选择的数组 和当前操作打√的数据比较，如果不同的就过滤留下，相同的就去除。
            this.selectOff = this.selectOff.filter(
              // 如果item.score不等于row.score就留下
              item => item.user_id !== row.user_id
            );
          }
        } else {
          this.selectOff = []
        }
      },
      //收藏列表数据
      async getCollectionList() {
        this.loading = true
        const {
          data: res
        } = await getCollectionListApi(this.page, this.limit)
        if (res.status != 10000) {
          this.$message.error(res.message)
        }
        this.collectList = res.result.list
        this.count = res.result.count
        this.loading = false
      },
      //头像图片处理
      imageSrc(row) {
        if (row.user_avatar) {
          return row.user_avatar
        } else if (row.user_avatar == null && row.gender == 0) {
          return require('../../assets/images/woman.jpg')
        } else if (row.user_avatar == null && row.gender == 1) {
          return require('../../assets/images/man.jpg')
        }
      },
      //跳转至学员详情页
      goStdDetails(row) {
        this.$router.push(`/pc/talentPool/detail?userId=${row.user_id}`)
      },
      //移除收藏
      async deleteCollect(user_id) {
        const {
          data: res
        } = await deleteCollectionListApi(user_id)
        if (res.status != 10000) {
          this.$message.error(res.message)
        }
        this.$message.success('移除收藏成功')
        this.getCollectionList()
      }
    },

  }

</script>

<style lang="less" scoped>
.resume-collection {
  strong {
    color: @primaryColor;
    font-size: 15px;
    font-weight: 600;
  }

  .c-title {
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
    border-bottom: 1px solid #e4e7ed;
    text-align: center;
  }

  .m-all {
    padding: 10px;
    // display: flex;
    justify-content: space-between;
    border: 1px solid #e4e7ed;
    border-top: 0;
  }

  .i-list {
    // display: flex;
    padding: 20px;

    .el-image {
      width: 50px;
      // height: 50px;
      border-radius: 100%;
      vertical-align: middle;
      // margin: -3px 0 0 10px;
      border: none;
    }

    .i-info {
      // display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    .i-name {
      font-weight: 600;
      text-align: left;
    }

    .i-content {
      display: flex;

      .c-info {
        display: flex;
        color: #8d92a1;
        margin-top: 10px;
        font-size: 12px;

        .i-item {
          // border-left: 1px solid #8d92a1;
          padding-right:10px;
          // height: 10px;
          line-height: 12px;
        }

        .i-item:last-child {
          padding-right: 0px;
          border-left: none;
        }
      }
    }
  }

  /deep/ .el-dialog__body {
    padding: 0;
  }

  /deep/.el-dialog__wrapper {
    top: 25%;
  }
  /deep/ .el-table__header-wrapper .el-checkbox {
    display: none;
  }

  .l-pagination {
    text-align: center;
    margin-top: 20px;
  }

  .certName{
      padding: 0 20px 20px;
      text-align: left;
      .cert{
        display: inline-block;
        background: #f56c6c;
        color: #fff;
        padding: 5px 8px;
        border-radius: 8px;
        font-size: 12px;
        margin: 0 6px 6px 0;
      }
    }
  
  .coll{
    text-align: left;
  }

  .userPhone{
      font-size: 16px;
      color: red;
      font-weight: bold;
      margin-top: 20px;
    }
  
  .Real span{
    display: inline-block;
    padding: 1px 5px;
    color: #0092ff;
    font-size: 12px;
    line-height: 1.5;
    border: 1px solid #EBEEF5;
    margin: 5px 0 0 0;
  }
}
</style>
